<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 17:54:32
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-22 13:32:00
-->
<template>
  <div>
    <div class="head">
      <div class="head-title">鸡思广益</div>
    </div>
    <!-- tab -->
    <div v-show="flag" class="tab">
      <el-button type="text" @click="$router.push('/news/report')">新闻中心</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="text" @click="$router.push('/news/strategy')">游戏攻略</el-button>
    </div>
    <!-- 子页面 -->
    <div style="margin-top: 50px">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      flag: true,
    }
  },
  watch: {
    $route(newVal) {
      if (newVal && newVal.name === "newsInfo") {
        this.flag = false
      } else {
        this.flag = true
      }
    },
  },
  mounted() {
    this.flag = this.$route.name !== "newsInfo"
  },
}
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 21vw;
  background: #00b0ec url(../assets/recharge/about_bg1.png) no-repeat center top;
  color: #fff;
  background-size: cover;
  padding-top: 6vw;
  box-sizing: border-box;
  .head-title {
    width: 100%;
    text-align: center;
    font-size: 4vw;
    color: #fff;
  }
}
.tab {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
</style>
